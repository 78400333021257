/*
 * General styles
 */

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

html {
  font-size: 16px;
  /*background-color: #4283F3;*/
  background-color: #F2F3F4;
  height: 100%;
}

body {
  background-color: #fff;
}

.hidden {
  display: none !important;
}

* {
  box-sizing: border-box;
}

body, .root, .App {
  font-family: 'Alata', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  display: flex;
  z-index: 999;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f2f3f4;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='80' height='80' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8a8 8 0 100-16 8 8 0 000 16zm40 40a8 8 0 100-16 8 8 0 000 16z' fill='%23d4d4d4' fill-opacity='.32' fill-rule='evenodd'/%3E%3C/svg%3E");
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Alatsi', sans-serif;
}

.content {
}

.fullpage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


/*
 * Form submit styles
 */

.form-submit-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0 auto;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  z-index: -99999;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  background-color: #4284F4;
  background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%232066db' fill-opacity='0.12'%3E%3Cpath d='M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40 40c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.form-submit-overlay.open {
  z-index: 999999;
  border-radius: 0;
  padding: 0 !important;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.form-submit-overlay .loading,
.form-submit-overlay .error-message,
.form-submit-overlay .success-message {
  display: none;
}

.form-submit-overlay.open .loading {
  display: block;
  font-size: 2rem;
  color: #fff;
}

.form-submit-overlay.open .loading.wide {
  display: none;
}

.form-submit-overlay.open .loading .dot-wrap {
  position: relative;
}

.form-submit-overlay.open .loading .dot-wrap .dot {
  position: relative;
}

.form-submit-overlay.open .loading .dot-wrap .dot:nth-child(1) {
  -webkit-animation: bounce 1s infinite ease-in-out;
  -moz-animation: bounce 1s infinite ease-in-out;
  animation: bounce 1s infinite ease-in-out;
}

.form-submit-overlay.open .loading .dot-wrap .dot:nth-child(2) {
  -webkit-animation: bounce 1.1s infinite ease-in-out;
  -moz-animation: bounce 1.1s infinite ease-in-out;
  animation: bounce 1.1s infinite ease-in-out;
}

.form-submit-overlay.open .loading .dot-wrap .dot:nth-child(3) {
  -webkit-animation: bounce 1.2s infinite ease-in-out;
  -moz-animation: bounce 1.2s infinite ease-in-out;
  animation: bounce 1.2s infinite ease-in-out;
}

.form-submit-overlay.open.success .loading,
.form-submit-overlay.open.success .loading.mobile,
.form-submit-overlay.open.success .loading.wide {
  display: none;
}

.form-submit-overlay.success {
  display: none;
}

.form-submit-overlay.open.success {
  display: flex;
}

.form-submit-overlay.open .success-message {
  display: none;
}

.form-submit-overlay.open.success .success-message {
  display: block;
  font-size: 1.5rem;
  color: #fff;
  font-weight: bold;
}

.form-submit-overlay .error-message,
.form-submit-overlay .error-message.sub {
  display: none;
}

.form-submit-overlay.open.error {
  background-color: #FF8787 !important;
}

.form-submit-overlay.open.error .loading,
.form-submit-overlay.open.error .loading.mobile,
.form-submit-overlay.open.error .loading.wide,
.form-submit-overlay.open.error .success-message,
.form-submit-overlay.open.error .success-message.sub,
.form-submit-overlay.open.error .success-message.sub.small {
  display: none;
}

.form-submit-overlay.error .error-message,
.form-submit-overlay.error .error-message.sub {
  display: block;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}

.form-submit-overlay.success .success-message.sub,
.form-submit-overlay.error .error-message.sub {
  font-size: 1.25rem;
  font-weight: normal;
}

.form-submit-overlay.success .success-message.sub.small,
.form-submit-overlay.error .error-message.sub.small {
  font-size: 0.9rem;
  padding-top: 0.5rem;
}

.form-submit-overlay.open .envelope {
  width: 90%;
  max-width: 18rem;
  margin-right: 2.5rem;
  padding-left: 1rem;
  height: 100%;
}

.form-submit-overlay.open .envelope #env {
  transform-origin: bottom;
  animation: envelopeBounce 1s ease-in infinite alternate;
}

.form-submit-overlay.open .envelope #star-one {
  transform-origin: bottom;
  transform-box: fill-box;
  animation: starOneBounce 1s ease infinite alternate;
}

.form-submit-overlay.open .envelope  #star-two {
  transform-origin: top;
  transform-box: fill-box;
  animation: starTwoBounce 2s ease-in-out infinite alternate;
}


/*
 * Animation styles
 */

@keyframes envelopeBounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-4px);
  }
}

@keyframes starOneBounce {
  from {
    transform: translateY(0px) rotate(0deg);
  }
  to {
    transform: translateY(3px) rotate(4deg);
  }
}

@keyframes starTwoBounce {
  from {
    transform: translateX(0px) rotate(0deg);
  }
  to {
    transform: translateX(5px) rotate(3deg);
  }
}

@-moz-keyframes bounce {
  0% {
    bottom: 0px;
  }
  20% {
    bottom: 4px;
  }
  25% {
    bottom: 5px;
  }
  40% {
    bottom: 0px;
  }
  100% {
    bottom: 0px;
  }
}

@-webkit-keyframes bounce {
  0% {
    bottom: 0px;
  }
  20% {
    bottom: 4px;
  }
  25% {
    bottom: 5px;
  }
  40% {
    bottom: 0px;
  }
  100% {
    bottom: 0px;
  }
}

@keyframes bounce {
  0% {
    bottom: 0px;
  }
  20% {
    bottom: 4px;
  }
  25% {
    bottom: 5px;
  }
  40% {
    bottom: 0px;
  }
  100% {
    bottom: 0px;
  }
}

@media only screen and (min-width: 420px) {
  .form-submit-overlay.open .loading.mobile {
    display: none;
  }

  .form-submit-overlay.open .loading.wide {
    display: block;
  }
}
