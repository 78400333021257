/*
 * Login styles
 */

.login-page {
  margin-top: 4.5rem;
}

.login-page .login {
  background: rgba(255,255,255,0.65);
  width: 100%;
  height: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.15s;
}

.login-page .login-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-page .login-wrap .login-primary {
  display: flex;
  flex-direction: column;
  background: rgba(255,255,255,0);
  padding: 1rem 1.5rem 2rem;
  z-index: 999;
  transition: 0.15s ease;
  max-width: 28rem;
}

.login-page .login-wrap .login-primary .login-header {
}

.login-page .login-wrap .login-primary .login-header h1 {
  margin: 1rem 0 0;
  text-align: center;
}

.login-page .login-wrap .login-primary .login-form-wrap {
}

.login-page .login-wrap .login-primary .login-form-wrap .login-form {
  display: flex;
  flex-direction: column;
}

.login-page .login-wrap .login-primary .login-form-wrap .login-form .email-wrap {
  position: relative;
  padding-bottom: 0.5rem;
}

.login-page .login-wrap .login-primary .login-form-wrap .login-form .email-wrap h3 {
  font-size: 1.5rem;
  margin: 0;
  padding: 2rem 0 1rem;
}

.login-page .login-wrap .login-primary .login-form-wrap .login-form .email-wrap p {
  margin: 0;
  padding: 0.25rem 0 1rem;
}

.login-page .login-wrap .login-primary .login-form-wrap .login-form .email-wrap .email {
  width: 100%;
  margin: 0;
  font-size: 1rem;
  line-height: 1rem;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  background-color: #fff;
  font-family: Alata, sans-serif;
  font-weight: 300;
  padding: 9px 15px 11px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
  outline: none;
  transition: all 0.3s ease-out;
}

.login-page .login-wrap .login-primary .login-form-wrap .login-form .email-wrap .email:focus {
  border-color: #3D7FEE;
}

.login-page .login-wrap .login-primary .login-form-wrap .login-form .email-wrap .emailTooltip {
  position: absolute;
  bottom: 12px;
  background: rgb(255, 242, 203);
  border: 1px solid #DAB335;
  border-radius: 3px;
  margin: 0 0 1.75rem 0.5rem;
  font-size: 0.9rem;
}

.login-page .login-wrap .login-primary .login-form-wrap .login-form .email-wrap .emailTooltip:after {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 0.25rem;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #DAB335;
}

.login-page .login-wrap .login-primary .login-form-wrap .login-form .email-wrap .emailTooltip p {
  padding: 0.25rem 0.75rem;
}

.login-page .login-wrap .login-primary .login-form-wrap .login-form .email-wrap.captcha {
  position: absolute;
  bottom: 0;
  right: 999999rem;
}

.login-page .login-wrap .login-primary .login-form-wrap .login-form .sub {
  font-size: 0.8rem;
  color: #565656;
  text-align: center;
}

.login-page .login-wrap .login-primary .login-form-wrap .login-form .link-wrap {
  margin-top: 1.75rem;
  text-align: center;
  position: relative;
}

.login-page .login-wrap .login-primary .login-form-wrap .login-form .link-wrap .login-button {
  padding: 0.5rem 1.5rem 0.75rem;
  background: #4285F4;
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  transition: 0.15s ease;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
}

.login-page .login-wrap .login-primary .login-form-wrap .login-form .link-wrap .login-button:hover {
  background: #1F66DA;
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.15);
}

.login-page .login-wrap .login-primary .login-footer {
}

.login-page .login-wrap .login-secondary {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 3px;
  padding: 1rem 1rem 3rem;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.35);
  margin: 2rem 1rem 2.5rem;
  z-index: 99;
  text-align: center;
}

.login-page .login-wrap .login-secondary h2 {
  margin-bottom: 0;
}

.login-page .login-wrap .login-secondary p {
  font-size: 0.9rem;
  color: #565656;
}

.login-page .login-wrap .login-secondary .link-wrap {
  margin-top: 1.75rem;
  text-align: center;
  position: relative;
}

.login-page .login-wrap .login-secondary .link-wrap .sign-up {
  padding: 0.5rem 1rem 0.75rem;
  font-size: 0.9rem;
  color: #000;
  background: #F0C808;
  text-decoration: none;
  transition: 0.15s ease;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
}

.login-page .login-wrap .login-secondary .link-wrap .sign-up:hover {
  background: #F5D331;
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.15);
}


/*
 * Media queries
 */

@media only screen and (min-width: 420px) {
  .login-page .login {
    background: rgba(255,255,255,0);
  }

  .login-page .login-wrap .login-primary {
    border-radius: 3px;
    background: rgba(255,255,255,1);
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
    padding: 1.5rem 2rem 4rem;
  }

  .login-page .login-wrap .login-secondary {
    margin: 0 1rem;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.15);
    padding: 1.5rem 2rem 4rem;
    border-radius: 0 0 3px 3px;
  }
}

@media only screen and (min-width: 900px) {
  .login-page .login-wrap {
    flex-direction: row;
  }

  .login-page .login-wrap .login-secondary {
    margin: 0;
    border-radius: 0 3px 3px 0;
  }
}
